import styled from "@emotion/styled";
import React from "react";
import type { FC } from "react";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import { useBloc } from "src/state/state";

const BrandPlacement = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: min(100%, 160px);
    display: block;
    height: auto;
  }
`;

const Branding: FC<{
  style?: React.CSSProperties;
}> = (props) => {
  const [, { activeSubscriptionHasBranding }] = useBloc(SubscriptionCubit);

  if (!activeSubscriptionHasBranding) {
    return null;
  }

  return <BrandPlacement style={props.style}></BrandPlacement>;
};

export default Branding;
